import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export default function useSearchUpdate() {
  const [params, setSearchParams] = useSearchParams();

  const update = useCallback(
    (values = {}) => {
      const origional = params.toString();
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          params.delete(key);
        } else {
          params.set(key, values[key]);
        }
      });
      const newParams = params.toString();
      if (origional !== newParams) {
        setSearchParams(newParams);
      }
    },
    [params]
  );

  return update;
}
