/* global gtag */
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://8aab7acc80a040359d5aaebf8f01bc30@o1149562.ingest.sentry.io/6221877",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// PAGES
window.React = React;
window["react-dom"] = ReactDOM;

ReactDOM.render(<App />, document.getElementById("root"));

function sendToGoogleAnalytics({ name, delta, value, id }) {
  if (typeof window !== "undefined" && window.gtag) {
    gtag("event", name, {
      value: delta,
      metric_id: id,
      metric_value: value,
      metric_delta: delta,
    });
  }
}
reportWebVitals(sendToGoogleAnalytics);
