/* global gtag */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useTracking = (trackingId) => {
  const location = useLocation();
  useEffect(() => {
    if (!window.gtag) return;
    if (!trackingId) {
      console.log(
        "Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
      );
      return;
    }

    window.gtag("config", trackingId, { page_path: location.pathname });
  }, [trackingId, location]);
};
