import { createContext, useContext, useState } from "react";
import styles from "./styles.module.css";

export const LoaderDisbatch = createContext(null);

export default function LoaderContext({ children }) {
  const [loading, setLoading] = useState(false);
  const handleSetLoading = (loading) => {
    if (loading) {
      setLoading(loading);
    } else {
      setTimeout(() => {
        setLoading(loading);
      }, 750);
    }
  };
  return (
    <LoaderDisbatch.Provider value={handleSetLoading}>
      <>
        {children}
        {loading && (
          <div className={styles.loader}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{ width: "320px", height: "auto" }}
            >
              <source
                src="/whale-small-hevc-safari.mp4"
                type='video/mp4; codecs="hvc1"'
              />
              <source src="/whale-small.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </>
    </LoaderDisbatch.Provider>
  );
}

export const useLoader = () => {
  return useContext(LoaderDisbatch);
};
