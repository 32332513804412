import { createContext, useEffect, useReducer, useState } from "react";
export const ToastDisbatch = createContext(null);

const reducer = (state, action) => {
  if (action.type === "set") {
    return action.value;
  }
};

class Queue {
  constructor(queue) {
    this.queue = queue ? queue : [];
    this._size = queue ? queue.length : 0;
    this.interval = null;
    this.callback = null;
  }

  get size() {
    return this._size;
  }
  set size(val) {
    this._size = val;
  }

  set(cb) {
    this.callback = cb;
  }

  get length() {
    return this.size;
  }

  add(data) {
    this.queue.push(data);
    this.loop();
    this._size++;
  }

  get() {
    return this.queue[0];
  }

  pop() {
    let item = this.queue.shift();
    this._size--;
    this.callback(item);
  }

  loop() {
    if (this.interval) return;
    this.pop();
    this.interval = setInterval(() => {
      if (this.size === 0) {
        this.callback(null);
        clearInterval(this.interval);
        this.interval = null;
        return;
      }
      this.pop();
    }, 2400);
  }
}

const toastQueue = new Queue();

export default function ToastContext({ children }) {
  const [state, dispatch] = useReducer(reducer, []);
  const [cur, setCur] = useState(null);

  useEffect(() => {
    toastQueue.set((item) => setCur(item));
  }, []);

  useEffect(() => {
    if (state.title || state.message) {
      toastQueue.add({ ...state, isa: Date.now() });
    }
  }, [state]);

  return (
    <ToastDisbatch.Provider value={dispatch}>
      {children}
      <Toast cur={cur} />
    </ToastDisbatch.Provider>
  );
}

function Toast({ cur }) {
  if (!cur) return null;

  return (
    <div className={`toast ${cur.type} show`}>
      <div className="toast-title">{cur.title}</div>
      <div className="toast-message">{cur.message}</div>
    </div>
  );
}
