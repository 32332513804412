import {
  IxVideoPlay,
  IxArrowRight,
  IxCloseCircle,
} from "@brevity-builder/icons";
import styles from "./styles.module.css";
import useSeachUpdate from "../hooks/usesearchupdate";

const tutorials = [
  {
    name: "Editor Intro",
    link: "https://youtu.be/Euh_ipe4LiA",
  },
  {
    name: "App authentication",
    link: "https://youtu.be/66hdgMF6qHo",
  },
  {
    name: "To-Do list app",
    link: "https://youtu.be/GVF0Hm9VyM0",
  },
];

export default function Tutorials({ close }) {
  const update = useSeachUpdate();

  const openLink = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div
      style={{
        flex: "1 1 0%",
        gap: 16,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 16,
          marginBottom: 16,
        }}
      >
        <div style={{ flex: 1 }} className={styles.title}>
          Select your tutorial
        </div>
        <IxCloseCircle onClick={close} className={styles.close} />
      </div>
      {tutorials.map((t) => {
        return (
          <button
            onClick={() => openLink(t.link)}
            key={t.name}
            className={styles.tutorial}
          >
            <IxVideoPlay />
            <div style={{ flex: 1 }}>{t.name}</div>
            <IxArrowRight className={styles.openArrow} />
          </button>
        );
      })}
    </div>
  );
}
