import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const prodConfig = {
  apiKey: "AIzaSyAjLfWXUekbURBMjGRtRCDckPEqOfvXCHo",
  authDomain: "deft-cef16.firebaseapp.com",
  databaseURL: "https://deft-cef16.firebaseio.com",
  projectId: "deft-cef16",
  storageBucket: "deft-cef16.appspot.com",
  messagingSenderId: "77815690977",
  appId: "1:77815690977:web:5e06cd85a502b5ba10e9a0",
};

firebase.initializeApp(prodConfig);

export const auth = firebase.auth();

export const functions = firebase.functions();

//functions.useEmulator("localhost", 5001);

export const storage = firebase.storage();

export const fb = firebase;

export const db = firebase.firestore();
export const realtimeDb = firebase.database();
db.settings({
  ignoreUndefinedProperties: true,
  merge: true,
});
// db.useEmulator("localhost", 8080);

const devKeys = {
  stripe:
    "pk_test_51HfC3sBLTRLftxZQnjzimRDVusPQq4pCOPlo4HhR7LVZ60GNxrjPKzQJYrsnXfEOYbLvROLJQfhMYmW9NPg1zP6G00s6mkO05e",
  cdn: "https://staging.uploads.brevitybuilder.com",
  preview:
    "http://localhost:5001/brevity-development/us-central1/apppreview/preview",
  bbs: "http://localhost:5050",
};

const prodKeys = {
  stripe:
    "pk_live_51HfC3sBLTRLftxZQ2Twn37YlwXLNoq6C5AabO5xuW1bhh3oNIHu14lwMmalEsdXZOpbhXL4A9xKg1Ej7lfyBFUn100plL53yee",
  // stripe:
  //   "pk_test_51HfC3sBLTRLftxZQnjzimRDVusPQq4pCOPlo4HhR7LVZ60GNxrjPKzQJYrsnXfEOYbLvROLJQfhMYmW9NPg1zP6G00s6mkO05e",
  cdn: "https://uploads.brevitybuilder.com",
  preview: "https://app.brevitybuilder.com/preview",
  //bbs: "http://localhost:5002",
  bbs: "https://apppreview.brevitypreview.com",
};

export const keys =
  process.env.REACT_APP_VERSION.trim() === "staging" ? devKeys : prodKeys;

export const getSnapData = (snapshot) => {
  const data = snapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return data;
};
