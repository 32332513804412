import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingContext from "./contexts/Loader";
import ToastContext from "./contexts/toast";
import UserContext from "./contexts/user";
import { keys } from "./firebase";
import Loader from "./ui/FullPageLoader";
import { useTracking } from "./useTracking";
import { ErrorFallback } from "./ui/ErrorFallback";

// Pages
import { AuthOutlet } from "./pages/auth"; // code split internally
import Root from "./pages/Root";
const Editor = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/Editor")
);
const Canvas = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/canvas")
);
const Data = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/data")
);
const Settings = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/settings")
);
const Connections = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/connections")
);
const SSO = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/sso")
);
const Home = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/home")
);
const Plugins = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/plugins")
);
const Preview = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/preview")
);

const PasswordReset = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/passwordReset")
);
const Signup = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/signup")
);
const Onboard = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/onboard")
);
const Verify = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/verify")
);
const Reset = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/reset")
);
const Login = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/login")
);
const Plugin = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/auth/plugin")
);

const WaitList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/wait-list")
);

const stripePromise = loadStripe(keys.stripe);

const BrevityRoutes = () => {
  useTracking("G-2Z0M4483WF");
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="wait-list" element={<WaitList />} />
        <Route path="/auth" element={<AuthOutlet />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="onboard" element={<Onboard />} />
          <Route path="verify" element={<Verify />} />
          <Route path="reset" element={<Reset />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="plugin" element={<Plugin />} />
        </Route>
        <Route path="/dashboard" element={<Home />} />
        <Route path="/:appId/:version" element={<Editor />}>
          <Route path="canvas" element={<Canvas />} />
          <Route path="database" element={<Data />} />
          <Route path="connections" element={<Connections />} />
          <Route path="plugins" element={<Plugins />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="/sso" element={<SSO />} />
        <Route path="/" element={<Root />} />
      </Routes>
    </React.Suspense>
  );
};

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      <UserContext>
        <Elements stripe={stripePromise}>
          <LoadingContext>
            <ToastContext>
              <Router>
                <BrevityRoutes />
              </Router>
            </ToastContext>
          </LoadingContext>
        </Elements>
      </UserContext>
    </Sentry.ErrorBoundary>
  );
};

export default App;
