import styles from "./styles.module.css";

export default function Loader() {
  return (
    <div style={{ width: 48, height: 48, position: "relative" }}>
      <div className={styles.peddle}></div>
      <div className={styles.peddle + " " + styles.one}></div>
      <div className={styles.peddle + " " + styles.two}></div>
      <div className={styles.peddle + " " + styles.three}></div>
      <div className={styles.peddle + " " + styles.four}></div>
      <div className={styles.peddle + " " + styles.five}></div>
      <div className={styles.peddle + " " + styles.six}></div>
      <div className={styles.peddle + " " + styles.seven}></div>
    </div>
  );
}
