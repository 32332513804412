import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useSearchListener(param) {
  const [params, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    const result = params.get(param);
    setValue(result);
  }, [params, param]);

  return value;
}
