import React from "react";
import Loader from "../loader";

import styles from "./styles.module.css";

const FullPageLoader = () => {
  const [showSpinner, setShowSpinner] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return showSpinner ? (
    <div className={styles.container}>
      <Loader />
    </div>
  ) : null;
};

export default FullPageLoader;
