import { auth } from "../firebase";
import Welcome from "../welcome";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Root() {
  const navigate = useNavigate();
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((u) => {
      if (u) {
        navigate("/dashboard", { replace: true });
      } else {
        navigate("/auth/login", { replace: true });
      }
    });
    return unsub;
  }, [navigate]);

  return <Welcome />;
}

export default Root;
