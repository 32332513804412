import * as React from "react";

export const ErrorFallback = ({ error, componentStack, resetError }) => {
  return (
    <div style={{ padding: "16px" }}>
      <h2>You have encountered an error.</h2>
      <p>{error.toString()}</p>
      <pre>
        <code>{componentStack}</code>
      </pre>
      <button
        style={{ padding: "8px 12px" }}
        onClick={() => {
          window.location = window.location.href.split("?")[0];
        }}
      >
        Click here to reload the page.
      </button>
    </div>
  );
};
