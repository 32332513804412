import decode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db, functions } from "../firebase";
import mixpanel from "../mixpanel";

export const UserValue = createContext();

export default function UserContext({ children }) {
  const [authUser, setAuthUser] = useState(null);
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const createJwt = functions.httpsCallable("createJwt");

  function getJwt() {
    const isExp = (token) => {
      let t = decode(token);
      return t.exp - Number.parseInt((Date.now() / 1000).toFixed()) < 5;
    };
    const token = window.localStorage.getItem("adminToken");

    if (!token || isExp(token)) {
      return createJwt()
        .then((jwt) => {
          window.localStorage.setItem("adminToken", jwt.data);
          setUserToken(jwt.data);
          return jwt.data;
        })
        .catch(() => false);
    }
    setUserToken(token);
  }

  useEffect(() => {
    let unsub = auth.onAuthStateChanged((u) => {
      setAuthUser(u);
    });
    return unsub;
  }, []);

  useEffect(() => {
    let unsub;
    if (authUser) {
      unsub = () => setUserToken(authUser.jwt);
    } else {
      setUserToken(null);
    }
    return unsub;
  }, [authUser]);

  useEffect(() => {
    let unsubUser;
    let unsubOrg;
    if (authUser) {
      unsubUser = db
        .collection("users")
        .doc(authUser.uid)
        .onSnapshot((doc) => {
          mixpanel.identify(doc.id);
          mixpanel.people.set({
            email: doc.data().email,
            name: doc.data().name,
          });
          setUser({ ...doc.data(), id: doc.id });
        });
      unsubOrg = db
        .collection("organizations")
        .where(`members.${authUser.uid}`, "!=", null)
        .onSnapshot((snap) => {
          const orgs = snap.docs.map((doc) => doc.id);
          setOrganizations(orgs);
        });
    } else {
      setUser(null);
    }
    return () => {
      unsubOrg?.();
      unsubUser?.();
    };
  }, [authUser]);

  useEffect(() => {
    if (!userToken && authUser) {
      getJwt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, authUser]);

  return (
    <UserValue.Provider
      value={{
        jwt: userToken,
        refreshToken: getJwt,
        organizations,
        ...user,
      }}
    >
      {children}
    </UserValue.Provider>
  );
}

export function useUser() {
  return useContext(UserValue);
}
