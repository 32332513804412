import useSearchListener from "../hooks/usesearchlistener";
import useSeachUpdate from "../hooks/usesearchupdate";
import styles from "./styles.module.css";
import { useState } from "react";
import Tutorials from "./tutorials";
import { IxCloseCircle } from "@brevity-builder/icons";
import { db } from "../firebase";
import { useUser } from "../contexts/user";
export default function Welcome() {
  const [show, setShow] = useState(false);
  const { newUser, id } = useUser();

  const openTutorial = () => {
    setShow(true);
  };

  const close = () => {
    db.collection("users").doc(id).update({ newUser: false });
  };

  if (!newUser) {
    return null;
  }

  return (
    <div className={styles.backdrop}>
      <div className={styles.welcome}>
        {!show ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 16,
              }}
            >
              <div className={styles.title}>👋</div>
              <div style={{ flex: 1 }} className={styles.title}>
                Welcome!
              </div>
              <IxCloseCircle onClick={close} className={styles.close} />
            </div>
            <div>
              <div className={styles.question}>
                How would you like to get started?
              </div>
              <div className={styles.content}>
                Brevity is a powerful platform and we want to make sure you can
                use its full potential.
              </div>
            </div>
            <div style={{ display: "flex", gap: 16 }}>
              <div className={styles.section}>
                <div
                  style={{ textAlign: "center" }}
                  className={styles.question}
                >
                  Let me start playing around
                </div>
                <button
                  onClick={close}
                  className={`${styles.button} ${styles.secondary}`}
                >
                  Let's go
                </button>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.section}>
                <div
                  style={{ textAlign: "center" }}
                  className={styles.question}
                >
                  I'd love to follow a tutorial first
                </div>
                <button onClick={openTutorial} className={styles.button}>
                  Launch me
                </button>
              </div>
            </div>
          </>
        ) : (
          <Tutorials close={close} />
        )}
      </div>
    </div>
  );
}
